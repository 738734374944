$magis-purple: #8d55ca !default;

.fw-bold {
  font-weight: 700 !important;
}

.toast-top-center {
  top: 10px !important;
  right: 0;
  width: 100%;
}

.ngx-datatable .datatable-body .datatable-body-row > div {
  align-items: center;
}

.theme-red .bs-datepicker-head {
  background-color: $magis-purple /*#8d55ca*/ !important;
}

.theme-red .bs-datepicker-body table td.week span {
  color: $magis-purple /*#8d55ca*/ !important;
}

.card-collapse .card-header {
  border-bottom: 1px solid $magis-purple /*#8d55ca*/ !important;
  padding: 25px 10px 5px 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
  background: #fff;
}

.card-collapse .card-header a {
  color: #c8d0da;
  font-size: 0.9375rem;
  display: block;
}

.theme-red .bs-datepicker-body table td span.selected,
.theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td span[class*="select-"]:after,
.theme-red .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: $magis-purple /*#8d55ca*/ !important;
}

.form-icon {
  margin-left: 2%;
  background: $magis-purple /*#8d55ca*/;
  border-radius: 4px;
  padding: 16px;
  color: #fff;
  position: absolute;
  margin-top: -20px;
}

.swal2-title {
  color: #8d55ca !important;
  font-weight: 500 !important;
  font-size: 20px !important;
}

.swal2-html-container {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 150% !important;

  color: #000000 !important;
}

.alert-button {
  background: transparent !important;
  color: $magis-purple /*#8d55ca*/ !important;
  box-shadow: unset !important;
  border: 2px solid $magis-purple /*#8d55ca*/ !important;
  border-radius: 32px;
  width: 180px;
  height: 43px;
  margin: 10px;
  outline: none !important;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #8d55ca;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal.fade {
  background: rgba(0, 0, 0, 0.5) !important;
}

.modal-backdrop.fade {
  opacity: 0 !important;
}

.modal-content .modal-header .modal-title {
  color: rgba(255, 255, 255, 0.8) !important;
}

.ngx-datatable
  .datatable-header
  .datatable-header-cell
  .datatable-header-cell-template-wrap {
  height: inherit;
  color: $magis-purple /*#8d55ca*/ !important; //rgba(255, 255, 255, 0.8);
}

.ngx-datatable
  .datatable-header
  .datatable-header-cell.sortable
  .datatable-header-cell-wrapper {
  cursor: pointer;
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  height: inherit;
}

ngx-datatable.ngx-datatable.bootstrap
  .datatable-header
  .datatable-header-cell:last-child,
ngx-datatable.ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-cell:last-child {
  text-align: center !important;
}


.modal-content {
  color: rgba(255, 255, 255, 0.6);
}

.spinner {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-top: 2px solid #fff;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: "1024px") {
  body {
    background-color: #fff !important;
    color: #3c4858 !important;
    font-weight: 300 !important;
  }

  .navbar-wrapper {
    display: flex;
    align-items: center;
    margin-left: 0% !important;
  }

  .card .card-body + .card-footer,
  .card .card-footer {
    margin: 0 15px 10px;
    border-radius: 0;
    justify-content: space-between;
    align-items: center;
  }

  .card {
    background-color: transparent;
    box-shadow: none !important;
    margin-top: 10px;
    min-height: max-content;
    margin-bottom: 0;
  }

  .card .card-body {
    padding: 0;
    position: relative;
  }

  .login-image {
    display: none;
  }

  .logo-image {
    margin-left: 4%;
    margin-right: 10%;
    margin-top: 10%;
    margin-bottom: 2%;
    width: 46px;
    height: 28px;
  }
}

.wizard-box-content {
  margin-left: 10% !important;
  margin-top: -20px !important;
}

.wizard-section-title {
  color: #b9b9b9 !important;
  font-size: 1.05rem;
  font-weight: 400;
  padding-bottom: 20px !important;
  padding-top: 200px !important;
  margin-bottom: 200px;
}

.input-top-margin {
  margin-top: 20px !important;
  // color: white;
}

.label-top-margin {
  margin-top: 28px !important;
}

.label-bold-top-margin {
  margin-top: 28px !important;
  font-weight: 600;
  color: white;
}

.label-bold {
  margin-top: 8px !important;
  font-weight: 600;
  color: white;
  //font-size: 1.25em !important;
}

.pac-container {
  //     //this is a fix for google autocomplete not showing up
  z-index: 10000 !important;
  //     display: block !important;
  //   top: 640px !important;
  //     // position: inherit !important;
}

.pac-container .pac-logo .hdpi {
  display: block !important;
  //top: 600px !important;
}

.pac-container:after {
  //this is a fix for google autocomplete not showing up
  // z-index: 10000 !important;
  // display: block !important;
  // top: 640px !important;
  display: none !important;
}

.swal2-container.swal2-center>.swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  width: 600px;
  justify-self: center;
}

.text-dialog{
  color:rgb(114 108 108 / 80%);
}

.swal2-cancel{
     background: #8d55ca !important;
    background-image: linear-gradient(to bottom left, #8d55ca, #ba54f5, #8d55ca)!important;
    background-size: 210% 210%!important;
    background-position: top right!important;
    background-color: #8d55ca!important;
    transition: all 0.15s ease;
    box-shadow: none;
    color: #ffffff!important;
}

// .datatable-header-cell-wrapper{
//   position: absolute !important;
// }
.left-aligned-header {
  display: flex !important; /* Use !important to ensure it takes precedence */
  }