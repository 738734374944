.wizard-container .card-wizard {
  .progress-bar {
    background: $white !important;
    .progress-bar {
      background: #8d55ca!important;
    }
  }
  .nav-pills .nav-item {
    width: 33%;
    position: relative !important;
    .step {
      width: 46px !important;
      height: 45px;
      position: absolute;
      .tim-icons {
        padding: 13px 0 15px 0 !important;
      }
    }
  }
}

.card-wizard[data-color=red] .nav-pills .nav-item .nav-link.active, .card-wizard[data-color=red] .nav-pills .nav-item .nav-link.checked:focus, .card-wizard[data-color=red] .nav-pills .nav-item .nav-link.checked:hover, .card-wizard[data-color=red] .nav-pills .nav-item .nav-link.active:focus, .card-wizard[data-color=red] .nav-pills .nav-item .nav-link.active:hover {
  color: #fff !important;
}

// .card-wizard[data-color=red] .nav-pills .nav-item .nav-link, .card-wizard[data-color=red] .nav-pills .nav-item .nav-link.checked:focus, .card-wizard[data-color=red] .nav-pills .nav-item .nav-link.checked:hover, .card-wizard[data-color=red] .nav-pills .nav-item .nav-link:focus, .card-wizard[data-color=red] .nav-pills .nav-item .nav-link {
//   background: #fff !important;
// }

.card-wizard[data-color=red] .nav-pills .nav-item .nav-link.checked, .card-wizard[data-color=red] .nav-pills .nav-item .nav-link.active {
  background: #8d55ca !important;
  color: white !important;
}

.card-wizard[data-color=red] .nav-pills .nav-item .nav-link {
  color: #000 !important;
}

